import "lazysizes";
import LogoScrollManager from "./logo-scroll.js";
import VideoPlayer from "./video";
import HeaderAnimation from "./header-animation";
new LogoScrollManager();

Array.from(document.querySelectorAll(".media--video")).forEach(
  (vid) => new VideoPlayer(vid)
);

new HeaderAnimation("#lottie");

console.error("This is a test error for QA");