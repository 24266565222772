
const DARK = Symbol('DARK');
const LIGHT = Symbol('LIGHT');

export default class LogoScrollWatcher {
  constructor() {
    this.els = {
      logo: document.querySelector(".header_logo"),
      sections: Array.from(document.querySelectorAll(".page_section"))
    }

    if(!this.els.logo || !this.els.sections) return

    window.addEventListener('resize', this.watchResize.bind(this))

    this.watchResize();
    this.loop = this.loop.bind(this);
    this.lastScrollOffset = window.scrollY;
    this.checkOffsets();
    window.requestAnimationFrame(this.loop)
  }

  getDimensions() {
    let logoRect = this.els.logo.getBoundingClientRect();
    let dims = {
      logo: {
        height: logoRect.height,
        offset: logoRect.top
      }
    }
  
    return dims;
  }

  watchResize() {
    this.dimensions = this.getDimensions();
  }

  checkOffsets() {
    this.els.sections.forEach(section => {
      let rect = section.getBoundingClientRect();
      if(rect.top <= this.dimensions.logo.offset && rect.top + rect.height >= this.dimensions.logo.offset + this.dimensions.logo.height) {
        this.logoForBg(this.checkBg(section));
      }
    })
  }

  checkBg(section) {
    return section.classList.contains('page_section--darkBg') ? DARK : LIGHT;
  }


  logoForBg(bg) {
    if(this.lastBg == bg) return;
    this.lastBg = bg;
    this.els.logo.classList.remove('header_logo--onDark', 'header_logo--onLight')
    let className = bg == DARK ? 'header_logo--onDark' : 'header_logo--onLight'
    this.els.logo.classList.add(className);
  }

  loop() {
    let offset = window.scrollY;
    if(offset == this.lastScrollOffset) {
      window.requestAnimationFrame(this.loop);
      return
    }
    this.lastScrollOffset = offset;
    this.checkOffsets();
    window.requestAnimationFrame(this.loop)
  }
}
