import "./vendor/lottie";
import { headerAnimationDesktop } from "./header-animation-desktop.lottie";
import { headerAnimationMobile } from "./header-animation-mobile.lottie";

class Dimensions {
  constructor({ width, height }) {
    this.width = width;
    this.height = height;
  }

  get ratio() {
    return this.width / this.height;
  }
}

export default class headerAnimation {
  constructor(holderSelector) {
    this.holder = document.querySelector(holderSelector);
    if (!this.holder) return;
    this.animations = {
      mobile: headerAnimationMobile,
      desktop: headerAnimationDesktop,
    };
    this.bp = window.matchMedia("(min-width: 768px");
    this.bp.addListener(this.onBpChange.bind(this));
    this.setHolderSize();
    this.onBpChange();
    window.addEventListener("resize", this.onResize.bind(this));
  }

  onResize() {
    if (this.animation) this.animation.destroy();
    clearTimeout(this.resizeTimeout);
    this.setHolderSize();
    this.resizeTimeout = setTimeout(() => {
      this.startAnimation();
    }, 300);
  }

  setHolderSize() {
    const canvasDimensions = new Dimensions(
      document.querySelector(".camoStage_patternHolder").getBoundingClientRect()
    );
    this.holder.style.width = canvasDimensions.width + "px";
    this.holder.style.height = canvasDimensions.height + "px";
  }

  onBpChange() {
    this.setHolderSize();
    this.startAnimation(this.bp.matches);
  }

  startAnimation() {
    if (this.animation) this.animation.destroy();
    this.animation = lottie.loadAnimation(
      this.getAnimationConfig(this.bp.matches)
    );
    this.animation.play();
  }

  getAnimationConfig(isDesktop) {
    return {
      container: this.holder,
      renderer: "html",
      loop: false,
      autoplay: false,
      animationData: {
        ...this.animations[isDesktop ? "desktop" : "mobile"],
        w: this.holder.clientWidth,
        h: this.holder.clientHeight,
      },
    };
  }
}
