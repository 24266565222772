export default class VideoPlayer {
  constructor(holder) {
    // get elements
    this.video = holder.querySelector("video");
    this.holder = holder;
    this.playButton = this.holder.querySelector(".heroMedia_playButton");

    // add click on play button
    if (this.playButton) {
      this.playButton.addEventListener("click", this.play.bind(this));
    }

    if (!this.video.getAttribute("disableInteract")) {
      // add click on holder
      this.holder.addEventListener("click", this.pause.bind(this));

      // add clicks on video wrapper
      this.video.addEventListener("play", this.onPlay.bind(this));
      this.video.addEventListener("pause", this.onPause.bind(this));
    }
  }

  onPlay(event) {
    this.holder.classList.add("heroMedia--playing");
  }

  onPause(event) {
    this.holder.classList.remove("heroMedia--playing");
  }

  play(event) {
    event.stopPropagation();
    this.video.play();
  }

  pause() {
    if (!this.video.paused) this.video.pause();
  }
}
